import React from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { AiFillEdit } from "react-icons/ai";

export default function FormPopup({
  type = "Course",
  formItems = [{ label: "", value: "", multiline: false, errorText: "" }],
  selected = {},
  setSelected = () => {},
  dataHandler = () => {},
  children,
}) {
  return (
    <Modal
      okText="Save"
      confirmLoading={selected.loading}
      centered
      open
      maskClosable={selected.view}
      okButtonProps={{ style: { borderRadius: 5 } }}
      cancelButtonProps={{ type: "danger", style: { borderRadius: 5 } }}
      maskStyle={{ opacity: 1 }}
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>
            {selected.new
              ? `Create New ${type}`
              : selected.view
              ? `View ${type} Details`
              : `Edit ${type} Details`}
          </h3>
          {selected.view && !selected.uneditable && (
            <Button
              onClick={() => setSelected({ ...selected, view: false })}
              shape="circle"
              style={{ marginRight: "2rem" }}
              type="primary"
              size="small"
              title={`Edit ${type}`}
            >
              <AiFillEdit />
            </Button>
          )}
        </div>
      }
      onOk={() => dataHandler(selected.new ? "create" : "update")}
      onCancel={() => setSelected({})}
      {...(selected.view ? { footer: null } : {})}
    >
      <Form
        style={{ maxHeight: "60vh", overflow: "auto", paddingRight: 15 }}
        name="course"
        layout={selected.view ? "horizontal" : "vertical"}
        requiredMark={false}
        labelAlign="left"
        className={selected.view ? "" : "form-container"}
        labelWrap
      >
        {children}
        {formItems.map((fi) => (
          <Form.Item
            key={fi.value}
            label={
              <div
                style={{
                  color: "#555",
                  fontSize: 13,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {fi.label}
                {selected.view ? null : !fi.notRequired ? (
                  <b style={{ marginLeft: 4, color: "red" }}>*</b>
                ) : (
                  <span style={{ marginLeft: 4, color: "#777" }}>
                    (Optional)
                  </span>
                )}
              </div>
            }
            labelCol={{
              span: fi.multiline ? 24 : 12,
              style: { paddingBottom: 0 },
            }}
            style={{ marginBottom: 6 }}
            required={!fi.notRequired}
            extra={
              selected.view ? null : (
                <span style={{ color: "red" }}>
                  {selected.showError
                    ? selected[fi.value]
                      ? fi.customErrorText || " "
                      : fi.errorText
                    : " "}
                </span>
              )
            }
          >
            {fi.custom ? (
              fi.custom
            ) : selected.view ? (
              <span
                style={{
                  fontSize: 15,
                  textTransform:
                    fi.multiline || fi.value === "email"
                      ? "none"
                      : "capitalize",
                  fontWeight: fi.multiline ? "400" : "500",
                  color: "#000",
                  overflowWrap: "anywhere",
                  whiteSpace: "pre-wrap",
                }}
              >
                {selected[fi.viewValue || fi.value] || "~"}
              </span>
            ) : fi.options ? (
              <Select
                value={selected[fi.value]}
                onChange={(v) => setSelected({ ...selected, [fi.value]: v })}
                placeholder={fi.placeholder}
              >
                {fi.options.map((opt) => (
                  <Select.Option key={opt.value} value={opt.value}>
                    {opt.label}
                  </Select.Option>
                ))}
              </Select>
            ) : fi.multiline ? (
              <Input.TextArea
                placeholder={fi.placeholder}
                value={selected[fi.value]}
                rows={fi.rows || 3}
                onChange={(e) =>
                  setSelected({ ...selected, [fi.value]: e.target.value })
                }
                {...fi.inputProps}
              />
            ) : fi.passwordInput ? (
              <Input.Password
                value={selected[fi.value]}
                onChange={(v) =>
                  setSelected({ ...selected, [fi.value]: v.target.value })
                }
                placeholder={fi.placeholder}
                type="password"
                {...fi.inputProps}
              />
            ) : (
              <Input
                value={selected[fi.value]}
                onChange={(v) =>
                  setSelected({ ...selected, [fi.value]: v.target.value })
                }
                placeholder={fi.placeholder}
                {...fi.inputProps}
              />
            )}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
}
