import React from "react";
import { Tabs } from "antd";

export default function TabsContainer({ onChange = () => {}, tabs = [] }) {
  //   {/* {tabs.map((tab) => (
  //     <Tabs.TabPane tab={tab.label} key={tab.label}>
  //       {tab.content}
  //     </Tabs.TabPane>
  //   ))}
  // </Tabs> */}
  return (
    <Tabs
      className="tabs-container"
      onChange={onChange}
      type="card"
      defaultActiveKey={tabs[0]?.label || "1"}
      items={tabs.map((tab) => ({
        key: tab.label,
        label: tab.label,
        children: tab.content,
      }))}
    />
  );
}
