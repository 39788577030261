import React, { useState } from "react";
import { message, Upload } from "antd";
// import { AiOutlineEdit, AiOutlineInfoCircle } from "react-icons/ai";

import {
  API_CALL,
  CHAPTER_SERVER,
  defaultParamObj,
  FORM_DATA_API_CALL,
  useGetListApi,
} from "../utils/network";
import TableContainer from "../components/TableContainer";
import ConfirmPopup from "../components/ConfirmPopup";
import FormPopup from "../components/FormPopup";
// import UploadView from "../components/UploadView";

const baseVoomlyVideoPlayURl =
  "https://embed.voomly.com/embed/assets/embed.html?type=v&videoId=";

const columns = [
  {
    title: "Chapter Video ID",
    dataIndex: "videoId",
    key: "videoId",
    render: (v = "") => (
      <a href={baseVoomlyVideoPlayURl + v} target="_blank" rel="noreferrer">
        {`${v.slice(0, 6)}...${v.slice(-6)}`}
      </a>
    ),
  },
  { title: "Chapter Title", dataIndex: "title", key: "title" },
  { title: "Chapter Length", dataIndex: "length", key: "length" },
];

const getDescAndLink = (string = "") => {
  const [description = "", anchorStr = ""] = string.split("<a");
  if (!anchorStr || !anchorStr.includes("</a>")) return { description };
  const linkName = anchorStr.split(">")[1].slice(0, -3);
  const link = anchorStr.split('href="')?.[1]?.split?.('"')?.[0] || linkName;
  return { description, link, linkName };
};

const ChapterTableView = ({ course = {} }) => {
  const [selected, setSelected] = useState({});
  const { getList, list, loading, paramObj } = useGetListApi({
    url: CHAPTER_SERVER + "getAll/" + course._id,
    params: { ...defaultParamObj, limit: 2 },
  });

  const dataHandler = (type = "") => {
    if (type === "delete") {
      setSelected({ ...selected, loading: true });
      API_CALL.delete(CHAPTER_SERVER + `${course._id}/${selected.deleteId}/0`)
        .then((res) => {
          if (res.status === 200) {
            if (list.length > 1 || paramObj.page === 1) getList();
            else getList({ ...paramObj, page: paramObj.page - 1 });
            message.error("Chapter deleted successfully");
          }
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    } else if (type === "create" || type === "update") {
      if (
        // (type === "create" && !selected.file) ||
        !selected.videoId ||
        !selected.videoRatio ||
        !selected.title ||
        !selected.length ||
        !selected.secretTip ||
        !selected.description
      ) {
        setSelected({ ...selected, showError: true });
      } else {
        setSelected({ ...selected, loading: true });
        const bodyFormData = new FormData();
        if (selected.file) bodyFormData.append("imageFile", selected.file);
        bodyFormData.append("title", selected.title);
        bodyFormData.append("videoId", selected.videoId);
        bodyFormData.append("videoRatio", selected.videoRatio);
        bodyFormData.append("length", selected.length);
        bodyFormData.append("secretTip", selected.secretTip);
        let description = selected.description;
        if (selected.link) {
          description += ` <a href="${selected.link}" target="_blank" rel="noreferrer">${selected.linkName}</a>`;
        }
        bodyFormData.append("description", description);
        const apiEndUrl = type === "create" ? course._id : selected._id;
        FORM_DATA_API_CALL({
          method: type === "create" ? "post" : "put",
          url: CHAPTER_SERVER + `${type}/${apiEndUrl}`,
          data: bodyFormData,
        })
          .then((res) => {
            if (res.status === 200) {
              getList();
              message.success("Chapter created successfully");
            }
            setSelected({});
          })
          .catch((err) => {
            console.log(err);
            setSelected({});
          });
      }
    }
  };

  return (
    <>
      {selected.deleteId && (
        <ConfirmPopup
          type="chapter"
          selected={selected}
          setSelected={setSelected}
          dataHandler={dataHandler}
        />
      )}
      {(selected.new || selected._id) && (
        <FormPopup
          type="Chapter"
          selected={selected}
          setSelected={setSelected}
          dataHandler={dataHandler}
          formItems={[
            // {
            //   label: "Video Thumbnail",
            //   value: "file",
            //   errorText: selected.videoThumbnail
            //     ? " "
            //     : "Please upload chapter video thumbnail",
            //   custom: (
            //     <UploadView
            //       urlKey="videoThumbnail"
            //       selected={selected}
            //       setSelected={setSelected}
            //     />
            //   ),
            // },
            {
              label: "Title",
              value: "title",
              errorText: "Please enter the chapter title",
              placeholder: "Enter chapter title",
            },
            {
              label: "Video ID",
              value: "videoId",
              errorText: "Please enter video ID",
              placeholder: "Enter video ID",
              ...(selected.view
                ? {
                    custom: (
                      <Upload
                        disabled
                        fileList={[
                          {
                            url: baseVoomlyVideoPlayURl + selected.videoId,
                            uid: "-1" + course._id,
                            status: "done",
                            name: selected.videoId,
                          },
                        ]}
                      />
                    ),
                  }
                : {}),
            },
            {
              label: "Video Ratio",
              value: "videoRatio",
              placeholder: "Enter video ratio ex. 1.78",
              errorText: "Please enter video ratio",
            },
            {
              label: "Video Length",
              value: "length",
              errorText: "Please enter chapter video length ex. 1.30",
              placeholder: "Enter chapter length",
            },
            {
              label: "Secret Tip",
              value: "secretTip",
              errorText: "Please enter chapter secret tip",
              placeholder: "Enter chapter secret tip",
              multiline: true,
              rows: 2,
            },
            {
              label: "Description",
              value: "description",
              errorText: "Please enter chapter description",
              placeholder: "Enter chapter description",
              multiline: true,
            },
            {
              label: "Additional Link",
              notRequired: true,
              value: "link",
              placeholder: "Enter additional learn more link",
              ...(selected.view && selected.link
                ? {
                    custom: (
                      <a href={selected.link} target="_blank" rel="noreferrer">
                        {selected.linkName}
                      </a>
                    ),
                  }
                : {}),
            },
            ...(!selected.view && selected.link
              ? [
                  {
                    label: "Additional Link Name",
                    notRequired: true,
                    value: "linkName",
                    placeholder: "Enter additional link name here",
                  },
                ]
              : []),
          ]}
        />
      )}
      <TableContainer
        type="Chapter"
        getList={getList}
        loading={loading}
        paramObj={paramObj}
        setSelected={(chapter = {}) =>
          chapter?.description && chapter.description.includes("<a")
            ? setSelected({
                ...chapter,
                ...getDescAndLink(chapter.description),
              })
            : setSelected(chapter)
        }
        columns={columns}
        dataSource={list}
        subTable
      />
    </>
  );
};

export default ChapterTableView;
